import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
const image4 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image4.png';

const PrintPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const labelUrl = searchParams.get('labelUrl');
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Track image load state

  useEffect(() => {
    if (!labelUrl) {
      navigate('/print-shipping-label'); // Redirect back if no label URL
    }
  }, [labelUrl, navigate]);

  useEffect(() => {
    if (isImageLoaded) {
      // Print only when the image has fully loaded
      setTimeout(() => {
        window.print();
      }, 100); // Add a slight delay to ensure rendering
    }
  }, [isImageLoaded]);

  if (!labelUrl) {
    return null; // Render nothing while redirecting
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
      <div className="relative max-w-[4in] max-h-[6in] w-full h-auto mb-6">
        {/* Background shipping label */}
        <img
          src={labelUrl}
          alt="Shipping Label"
          className="w-full h-auto"
          onLoad={() => setIsImageLoaded(true)} // Set the image as loaded when done
        />
        {/* Company Logo */}
        <img
          src={image4}
          alt="Company Logo"
          className="absolute top-4 left-4 w-16 h-auto opacity-80"
        />
      </div>
      <button
        onClick={() => window.print()} // Trigger the print dialog manually
        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 shadow-md transform hover:scale-105 transition-transform duration-300 print:hidden"
      >
        🖨️ Print Again
      </button>

      {/* Print-specific styles */}
      <style>
        {`
          @media print {
            .print:hidden {
              display: none !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PrintPage;
