import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
const image4 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image4.png';

const PrivacyPolicy: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#c3e7f5] via-[#0e4668] to-[#121212]">
    <header className="w-full bg-[#b3dff5] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
    <div className="flex items-center justify-center flex-1">
      <Link to="/" className="text-3xl font-bold text-gray-900 flex items-center space-x-3 hover:text-blue-700 transition duration-300" aria-label="BooksAreReborn Home">
        <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto animate-bounce" />
        <span className="tracking-wider">BooksAreReborn</span>
      </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Your Account
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Cart
            </Link>

          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
            >
              Log out
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate('/auth')}
                className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign In
              </button>
              <button
                onClick={handleSignUpClick}
                className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign Up
              </button>
            </>
          )}
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#57b0dc] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 shadow-xl rounded-l-2xl">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link to="/" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Home
                </Link>
                <Link to="/your-account" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Your Account
                </Link>
                <Link to="/get-offer" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Cart
                </Link>
              </div>
              <div className="py-6">
                {currentUser ? (
                  <button
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => navigate('/auth')}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      <main className="flex-grow pt-24 flex justify-center items-center">
      <section className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
        <p className="text-gray-700 mb-4">Last Updated: 9/21/2024</p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Introduction</h2>
        <p className="text-gray-700 mb-4">
          BooksAreReborn ("we," "us," or "our") operates the BooksAreReborn website and related services. This Privacy Policy governs the manner in which we collect, use, maintain, and disclose information collected from users ("User" or "you") of the BooksAreReborn website ("Site"). This policy applies to the Site and all products and services offered by BooksAreReborn.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Personal Identification Information</h2>
        <p className="text-gray-700 mb-4">
          We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, provide their PayPal email or mailing address for payment, subscribe to the newsletter, or engage with other activities, services, features, or resources available on our Site. Users may be asked for their name, email address, mailing address, phone number, and payment preferences. Users can always refuse to supply personal identification information, but it may prevent them from engaging in certain Site-related activities.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Non-Personal Identification Information</h2>
        <p className="text-gray-700 mb-4">
          We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer, and technical information about Users' means of connection to our Site, such as the operating system, internet service providers utilized, and other similar information.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Web Browser Cookies</h2>
        <p className="text-gray-700 mb-4">
          Our Site uses cookies to enhance User experience. These cookies may be used for analytics purposes (e.g., Google Analytics 4) to track site performance and understand user behavior. Users' web browsers place cookies on their hard drive for record-keeping purposes. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent. However, refusing cookies may affect certain features of the Site. For more information, refer to this Privacy Policy.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">How We Use Collected Information</h2>
        <p className="text-gray-700 mb-4">
          BooksAreReborn may collect and use Users' personal information for the following purposes:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li><strong>To process payments:</strong> User-provided PayPal emails or mailing addresses are used exclusively for issuing payments for book transactions.</li>
          <li><strong>To improve customer service:</strong> The information you provide helps us respond to your customer service requests and support needs more efficiently.</li>
          <li><strong>To personalize user experience:</strong> We may use information in aggregate to understand how Users as a group use the services and resources provided on our Site.</li>
          <li><strong>To improve our Site:</strong> Feedback you provide helps us improve our products and services.</li>
        </ul>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">How We Protect User Information</h2>
        <p className="text-gray-700 mb-4">
          We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of Users' personal information, including PayPal emails and mailing addresses.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Sharing User Personal Information</h2>
        <p className="text-gray-700 mb-4">
          We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and Users with our business partners, trusted affiliates, and advertisers for analytics purposes. We may also share data with third-party service providers (e.g., analytics services like Google Analytics) to help us operate our business and the Site or administer activities on our behalf.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Deletion and Access Requests</h2>
        <p className="text-gray-700 mb-4">
          Users have the right to request access to their personal data or request its deletion. To exercise these rights, please contact us via the <Link to="/contact-us" className="text-blue-500 hover:underline">Contact Us</Link> page.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Changes to This Privacy Policy</h2>
        <p className="text-gray-700 mb-4">
          BooksAreReborn may update this Privacy Policy at any time. When we do, we will revise the updated date at the top of this page. Users are encouraged to frequently check this page for any changes to stay informed about how we are protecting their personal information.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Your Acceptance of These Terms</h2>
        <p className="text-gray-700 mb-4">
          By using this Site, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Contacting Us</h2>
        <p className="text-gray-700">
          If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, or if you want to request the deletion of your data, please contact us via our <Link to="/contact-us" className="text-blue-500 hover:underline">Contact Us</Link> page.
        </p>
      </section>
    </main>

      <br />
      <footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
        <a
          href="https://www.affiliatly.com/af-1070204/affiliate.panel?mode=register"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Affiliate Program
        </a>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>

    </div>
  );
};

export default PrivacyPolicy;
