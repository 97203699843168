import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
const image4 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image4.png';

const AcceptableUse: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#c3e7f5] via-[#0e4668] to-[#121212]">
    <header className="w-full bg-[#b3dff5] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
    <div className="flex items-center justify-center flex-1">
      <Link to="/" className="text-3xl font-bold text-gray-900 flex items-center space-x-3 hover:text-blue-700 transition duration-300" aria-label="BooksAreReborn Home">
        <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto animate-bounce" />
        <span className="tracking-wider">BooksAreReborn</span>
      </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Your Account
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Cart
            </Link>

          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
            >
              Log out
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate('/auth')}
                className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign In
              </button>
              <button
                onClick={handleSignUpClick}
                className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign Up
              </button>
            </>
          )}
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#57b0dc] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 shadow-xl rounded-l-2xl">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link to="/" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Home
                </Link>
                <Link to="/your-account" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Your Account
                </Link>
                <Link to="/get-offer" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Cart
                </Link>

              </div>
              <div className="py-6">
                {currentUser ? (
                  <button
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => navigate('/auth')}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      <main className="flex-grow pt-24 flex justify-center items-center">
    <section className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg text-center">
      <h1 className="text-4xl font-bold text-gray-900 mb-4">Acceptable Use Policy</h1>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">1. These Terms</h2>
      <p className="text-gray-700 mb-4">
        Welcome to BooksAreReborn. These terms govern your use of our website and services. By using our services, you agree to comply with these terms and any other policies referenced within.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Who We Are and How to Contact Us</h2>
      <p className="text-gray-700 mb-4">
        BooksAreReborn is operated by TechBaR LLC. You can contact us at <a href="mailto:contact@booksarereborn.com" className="text-blue-500 hover:underline">contact@booksarereborn.com</a>. Please include relevant details when contacting us for support.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Privacy and Acceptable Use</h2>
      <p className="text-gray-700 mb-4">
        We collect and process your personal information in accordance with our <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a>. By using our website, you agree to comply with our Acceptable Use Policy, ensuring that your actions do not harm others or disrupt our services. Prohibited activities include:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Submitting stolen, counterfeit, or illegal items.</li>
        <li>Providing false or misleading information.</li>
        <li>Attempting to manipulate pricing or valuation systems.</li>
        <li>Engaging in abusive, harassing, or fraudulent activities.</li>
      </ul>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">4. This Contract and Your Promises</h2>
      <p className="text-gray-700 mb-4">
        When you create an account or submit items on our site, you enter into a contract with us. You promise that all information you provide is accurate, that you are the legal owner of any items you sell, and that you will not misuse our platform.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Acceptance Criteria for Items</h2>
      <p className="text-gray-700 mb-4">
        Items you wish to sell must meet our acceptance criteria. This includes being in good condition, free from damage, and not being counterfeit or illegal. Items that fail to meet these standards may be rejected.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Rejection of Items and Disposal</h2>
      <p className="text-gray-700 mb-4">
        Rejected items will not be returned unless specifically requested within 7 days of notification. Return shipping costs are the responsibility of the user. Items not reclaimed within this period will be disposed of responsibly. Counterfeit or illegal items may be reported to law enforcement.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Valuation and Pricing of Your Items</h2>
      <p className="text-gray-700 mb-4">
        Valuations are based on market conditions and are valid for 24 hours. Prices may change if shipping is delayed beyond this period. Final valuations are at our discretion following inspection.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Shipping and Delivery</h2>
      <p className="text-gray-700 mb-4">
        When sending items to us, ensure they are securely packaged and shipped using our provided labels. We are not responsible for items lost or damaged in transit due to improper packaging.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Receipt, Ownership, Risk, and Processing of Items</h2>
      <p className="text-gray-700 mb-4">
        Ownership of items passes to us only after successful inspection. We will process items promptly and notify you of their acceptance or rejection. We are not liable for loss or damage until items are inspected and accepted.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">10. Payment Terms</h2>
      <p className="text-gray-700 mb-4">
        Payments for accepted items are issued within 3-5 business days after inspection. Ensure that your PayPal email or mailing address is accurate to avoid delays. Payments may be withheld in cases of suspected fraud or incomplete user information.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">11. Offers, Bonuses, and Competitions</h2>
      <p className="text-gray-700 mb-4">
        Promotions, bonuses, and competitions are subject to additional terms and conditions, including eligibility and minimum trade requirements. We reserve the right to revoke offers if these terms are not met or in cases of fraud or misuse.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">12. Right to Refuse Service</h2>
      <p className="text-gray-700 mb-4">
        We reserve the right to refuse service, cancel transactions, or terminate accounts for users who violate this policy or engage in prohibited activities.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">13. Limitation of Liability</h2>
      <p className="text-gray-700 mb-4">
        To the fullest extent permitted by law, we are not liable for delays, lost shipments, or damages caused by third-party carriers. Our liability is limited to the value of accepted items.
      </p>
    </section>
  </main>

      <br />
      <footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>
    </div>
  );
};

export default AcceptableUse;
