import React, { useEffect, ReactNode } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Getoffer from './components/Getoffer';
import AuthPage from './components/AuthPage';
import YourAccount from './components/YourAccount';
import ReviewShipment from './components/ReviewShipment';
import ShipmentSuccess from './components/ShipmentSuccess';
import PrivateRoute from './components/PrivateRoute';
import AdminPanel from './components/AdminPanel';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import AcceptableUse from './components/AcceptableUse';
import BuyBackGuidePage from './components/BuyBackGuidePage';
import ContactUs from './components/ContactUs';
import FAQ from './components/FAQ';
import CookiesConsent from './components/CookiesConsent'; // Import CookiesConsent component
import { AuthProvider } from './contexts/AuthContext';
import loadAnalytics from './load-analytics';
import ShippingLabelPrint from './components/ShippingLabelPrint'; // Adjust the path if necessary
import PrintPage from './components/PrintPage';



// Error boundary component with reset on navigation
interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state to show fallback UI after an error
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('Error captured by ErrorBoundary: ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again.</h1>;
    }

    return this.props.children;
  }
}

// Error boundary wrapper with location tracking
const ErrorBoundaryWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation(); // Ensure location is passed correctly to trigger reset on navigation

  return (
    <ErrorBoundary key={location.pathname}>
      {children}
    </ErrorBoundary>
  );
};

// Track page views when route changes, must be within Router context
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = getPageTitle(location.pathname); // Update document title based on route
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
        page_location: window.location.href,
      });
    }
  }, [location]);
};

// Helper function to set the title dynamically based on the path
const getPageTitle = (pathname: string) => {
  switch (pathname) {
    case '/':
      return 'Home - BooksAreReborn';
    case '/get-offer':
      return 'Get Offer - BooksAreReborn';
    case '/auth':
      return 'Login - BooksAreReborn';
    case '/your-account':
      return 'Your Account - BooksAreReborn';
    case '/review-shipment':
      return 'Review Shipment - BooksAreReborn';
    case '/shipment_success':
      return 'Shipment Success - BooksAreReborn';
    case '/admin-panel':
      return 'Admin Panel - BooksAreReborn';
    case '/privacy-policy':
      return 'Privacy Policy - BooksAreReborn';
    case '/terms-of-use':
      return 'Terms of Use - BooksAreReborn';
    case '/acceptable-use':
      return 'Acceptable Use - BooksAreReborn';
    case '/buyback-guide':
      return 'Buyback Guide - BooksAreReborn';
    case '/contact-us':
      return 'Contact Us - BooksAreReborn';
    case '/faq':
      return 'FAQ - BooksAreReborn';
    case '/print-shipping-label':
      return 'Print Shipping Label - BooksAreReborn';
    default:
      return 'BooksAreReborn';
  }
};

const App: React.FC = () => {
  useEffect(() => {
    // Initialize Google Analytics
    loadAnalytics();
  }, []);

  return (
    <Router>
      <AuthProvider>
        <ErrorBoundaryWrapper>
          <PageTracker /> {/* This ensures the useLocation call is within the Router */}
          <CookiesConsent />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/get-offer" element={<Getoffer />} />
            <Route path="/your-account" element={<PrivateRoute element={<YourAccount />} />} />
            <Route path="/review-shipment" element={<PrivateRoute element={<ReviewShipment />} />} />
            <Route path="/shipment_success" element={<PrivateRoute element={<ShipmentSuccess />} />} />
            <Route path="/admin-panel" element={<PrivateRoute element={<AdminPanel />} isAdminRoute />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/acceptable-use" element={<AcceptableUse />} />
            <Route path="/buyback-guide" element={<BuyBackGuidePage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/print-shipping-label" element={<ShippingLabelPrint />} /> {/* Add the new route */}
            <Route path="/print" element={<PrintPage />} />
          </Routes>
        </ErrorBoundaryWrapper>
      </AuthProvider>
    </Router>
  );
};

// This component handles tracking page views on route change
const PageTracker = () => {
  usePageTracking();
  return null;
};

export default App;
