import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { auth, rtdb, db } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { ref, onDisconnect, update } from 'firebase/database';
import ga from '../ga-init'; // Import Google Analytics
import Notification from '../components/Notification'; // Import Notification component


interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<any>(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [errorQueue, setErrorQueue] = useState<{ message: string, type: 'success' | 'error' | 'info' | 'warning' }[]>([]);
  const [currentError, setCurrentError] = useState<{ message: string, type: 'success' | 'error' | 'info' | 'warning' } | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [logoutTimer, setLogoutTimer] = useState<ReturnType<typeof setTimeout> | null>(null); // To track the logout timer


  // Function to show notifications sequentially from the error queue
  const showNotificationInOrder = () => {
    if (errorQueue.length > 0) {
      setCurrentError(errorQueue[0]);
      setShowNotification(true);
    }
  };

  // Add new notification to the error queue
  const addToErrorQueue = (message: string, type: 'success' | 'error' | 'info' | 'warning') => {
    setErrorQueue(prevQueue => prevQueue.concat({ message, type })); // Use concat to add new item
    if (!currentError) {
      showNotificationInOrder(); // Trigger showing the first notification
    }
  };
  

  // Handle notification close and show the next error in the queue
  const handleNotificationClose = () => {
    setShowNotification(false);
    setErrorQueue((prevQueue) => {
      const nextQueue = prevQueue.slice(1); // Remove the first error from the queue using slice
      if (nextQueue.length === 0) {
        setCurrentError(null); // No more errors to show
      } else {
        setCurrentError(nextQueue[0]); // Show the next error
      }
      return nextQueue; // Return the updated queue
    });
  };
  
  
  useEffect(() => {
    if (errorQueue.length > 0) {
      showNotificationInOrder();  // Show the next error when one is closed
    }
  }, [errorQueue]);

  // Monitor auth state and handle user login/logout
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (!user.emailVerified) {
          await signOut(auth);
          addToErrorQueue('Please verify your email before logging in.', 'error');
          setLoading(false);
          return;
        }

        const userStatusDatabaseRef = ref(rtdb, '/status/' + user.uid);
        const isOfflineForDatabase = {
          state: 'offline',
          last_changed: new Date().toISOString(),
        };

        const isOnlineForDatabase = {
          state: 'online',
          last_changed: new Date().toISOString(),
          email: user.email,
        };

        onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase).then(() => {
          update(userStatusDatabaseRef, isOnlineForDatabase);
        });

        setCurrentUser(user);

        const cookieConsent = localStorage.getItem('cookieConsent');
        if (cookieConsent === 'accepted') {
          ga.setUserId(user.uid);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Sign up new users and send email verification
  const signup = async (email: string, password: string) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods.includes(GoogleAuthProvider.PROVIDER_ID)) {
        addToErrorQueue('This email is already registered with Google Sign-In. Please log in using Google.', 'info');
        return;
      }

      if (signInMethods.includes('password')) {
        addToErrorQueue('This email is already registered with an email and password. Please log in.', 'info');
        return;
      }

      const result = await createUserWithEmailAndPassword(auth, email, password);

      await sendEmailVerification(result.user);
      addToErrorQueue('A verification email has been sent. Please verify your email before logging in.', 'success');

      await setDoc(doc(db, 'users', result.user.uid), { uid: result.user.uid, email: result.user.email });

      await signOut(auth);

      return result;
    } catch (error) {
      handleAuthError(error);
    }
  };

// Improved handleAuthError function
const handleAuthError = (error: any) => {
  console.error("Auth Error:", error); // Log the full error for debugging
  const errorCode = error.code || '';
  const errorMessage = error.message || '';

  if (errorCode) {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        addToErrorQueue('This email is already registered. Please log in.', 'error');
        break;
      case 'auth/user-not-found':
        addToErrorQueue('User not found. Please check your credentials or sign up.', 'error');
        break;
      case 'auth/invalid-credential':
        addToErrorQueue('Incorrect password or email. Please try again.', 'error');
        break;
      case 'auth/too-many-requests':
        addToErrorQueue('Too many requests. Please wait and try again later.', 'warning');
        break;
      case 'auth/network-request-failed':
        addToErrorQueue('Network error. Please check your connection and try again.', 'error');
        break;
      default:
        addToErrorQueue(`Error: ${errorMessage}`, 'error');
        break;
    }
  } else {
    addToErrorQueue('Failed to authenticate. Please try again.', 'error');
  }
};


  // Login users but enforce email verification
  //C:\Users\omerb\OneDrive\Desktop\NewSeller\frontend\src\contexts\AuthContext.tsx
  const login = async (email: string, password: string, navigate: Function) => {
    console.log("Login credentials:", email, password);
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
  
      if (!result.user.emailVerified) {
        addToErrorQueue('You are logging in, please wait.', 'info');
        await signOut(auth);
        addToErrorQueue('Please verify your email before logging in.', 'error');
        return;
      }
  
      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent === 'accepted') {
        ga.trackEventBuilder('Auth')({ action: 'Login', label: email });
      }
  
      // Navigate to the get-offer page after successful login
      navigate('/get-offer');
  
      return result;
    } catch (error) {
      handleAuthError(error); // Use improved error handler
    }
  };
  
  

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const signInMethods = await fetchSignInMethodsForEmail(auth, user.email!);

      if (signInMethods.includes('password')) {
        addToErrorQueue('This email is already registered with an email and password. Please log in.', 'info');
        await signOut(auth);
        return;
      }

      await setDoc(doc(db, 'users', user.uid), { uid: user.uid, email: user.email, displayName: user.displayName, photoURL: user.photoURL }, { merge: true });

      if (!user.emailVerified) {
        await sendEmailVerification(user);
        await signOut(auth);
        throw new Error('Please verify your email before logging in.');
      }

      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent === 'accepted') {
        ga.trackEventBuilder('Auth')({ action: 'Login with Google', label: user.email || 'Google User' });
      }

      return result;
    } catch (error) {
      handleAuthError(error);
    }
  };

  // Logout the user after 15 minutes of inactivity
  // Logout the user after inactivity or other reasons
// Function to display a styled notification
const showLogoutNotification = (message: string) => {
  // You can replace this with a custom Toast/Alert component or library like 'react-toastify'
  const notification = document.createElement('div');
  notification.className = 'logout-notification';  // Add styling class for the notification
  notification.innerText = message;
  
  // Add the notification to the body or a container
  document.body.appendChild(notification);

  // Auto-remove the notification after 3 seconds
  setTimeout(() => {
    document.body.removeChild(notification);
  }, 5000);
};

// Example CSS for the notification
const notificationStyles = `
  .logout-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    z-index: 9999;
  }
`;

// Add styles to the page (this can also be included in your main CSS file)
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = notificationStyles;
document.head.appendChild(styleSheet);

const logout = async (reason?: string) => {
  if (currentUser) {
    const userStatusDatabaseRef = ref(rtdb, '/status/' + currentUser.uid);
    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: new Date().toISOString(),
    };
    await update(userStatusDatabaseRef, isOfflineForDatabase);
  }

  const cookieConsent = localStorage.getItem('cookieConsent');
  if (cookieConsent === 'accepted') {
    ga.trackEventBuilder('Auth')({ action: 'Logout', label: currentUser && currentUser.email ? currentUser.email : 'User' });
  }

  // Show a styled notification to inform the user why they were logged out
  if (reason) {
    showLogoutNotification(`Session ended: ${reason}`);
  }

  return signOut(auth);
};

  
// Store the current timestamp when the user leaves the page
const handleTabCloseOrUnload = () => {
  if (currentUser) {
    localStorage.setItem('lastActiveTime', String(Date.now()));  // Store the current time as the last active time
  }
};

// Check if the user was inactive for more than 30 seconds
const checkLastActiveTime = async () => {
  const lastActiveTime = Number(localStorage.getItem('lastActiveTime')) || 0;
  const currentTime = Date.now();

  // If the user was inactive for more than 30 seconds, log them out
  if (currentUser && (currentTime - lastActiveTime > 150* 60 * 1000)) {
    await logout('You were logged out due to inactivity');
  }
};

useEffect(() => {
  // Listen for tab close or unload events to store the last active time
  window.addEventListener('beforeunload', handleTabCloseOrUnload);

  // Check the last active time when the user returns
  checkLastActiveTime();

  return () => {
    window.removeEventListener('beforeunload', handleTabCloseOrUnload);
  };
}, [currentUser]);

// Reset the last active time when the user interacts with the page
const resetLastActiveTime = () => {
  localStorage.setItem('lastActiveTime', String(Date.now()));
};

// Reset the last active time on user interaction (so they're not logged out prematurely)
useEffect(() => {
  const events = ['mousemove', 'keydown', 'scroll', 'touchstart', 'touchmove'];
  events.forEach((event) => window.addEventListener(event, resetLastActiveTime));

  return () => {
    events.forEach((event) => window.removeEventListener(event, resetLastActiveTime));
  };
}, [currentUser]);

  // Check and clear any previous logout timer on tab reopen
  const checkForLogoutTimer = () => {
    const storedTimer = localStorage.getItem('logoutTimer');
    if (storedTimer) {
      clearTimeout(Number(storedTimer));
      localStorage.removeItem('logoutTimer');
    }
  };
  
  useEffect(() => {
    const events = ['beforeunload', 'unload'];
  
    // Clear any previous logout timers when the tab is reopened
    checkForLogoutTimer();
  
    // Add event listeners for tab close or unload
    events.forEach((event) => window.addEventListener(event, handleTabCloseOrUnload));
  
    return () => {
      // Clean up event listeners
      events.forEach((event) => window.removeEventListener(event, handleTabCloseOrUnload));
    };
  }, [currentUser]);
  
  // Reset inactivity timeout on user interaction
  const resetInactivityTimeout = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  
    const timeout = setTimeout(async () => {
      if (currentUser) {
        await logout('due to inactivity');
        console.log("User logged out due to inactivity.");
      }
    }, 150* 60 * 1000); // 30 seconds timeout
  
    setLogoutTimer(timeout);
    localStorage.setItem('inactivityTimeout', String(timeout));
  };
  
  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart', 'touchmove'];
    const resetTimeout = () => resetInactivityTimeout();
  
    // Add event listeners to reset the inactivity timeout on user activity
    events.forEach((event) => window.addEventListener(event, resetTimeout));
  
    return () => {
      // Clean up event listeners when component unmounts
      events.forEach((event) => window.removeEventListener(event, resetTimeout));
    };
  }, [currentUser]);
  
  // Function to handle password reset
  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
  
      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent === 'accepted') {
        ga.trackEventBuilder('Auth')({ action: 'Password Reset', label: email });
      }
  
      addToErrorQueue('Password reset email sent.', 'success');
    } catch (error) {
      handleAuthError(error);
    }
  };
  
  return (
    <AuthContext.Provider value={{ currentUser, signup, login, loginWithGoogle, logout, resetPassword, handleAuthError,addToErrorQueue }}>
      {!loading && children}
      {currentError && (
        <Notification message={currentError.message} type={currentError.type} show={showNotification} onClose={handleNotificationClose} />
      )}
    </AuthContext.Provider>
  );
};