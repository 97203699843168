import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MobileView: React.FC<{ labelUrl: string; handlePrintRedirect: () => void }> = ({ labelUrl, handlePrintRedirect }) => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-teal-100 to-blue-200 p-6">
    <h1 className="text-3xl font-bold text-center mb-6 text-purple-600 animate-bounce">
      📦 Mobile View: Shipping Label
    </h1>
    <div
      className="bg-[#A0522D] p-6 border-4 border-[#5C4033] rounded shadow-lg cursor-pointer"
      onClick={handlePrintRedirect} // Trigger printing when the label container is clicked
    >
      <img
        src={labelUrl}
        alt="Shipping Label"
        className="max-w-full max-h-[4in] w-auto h-auto"
      />
    </div>
    <div className="mt-4">
      <button
        onClick={handlePrintRedirect}
        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
      >
        🖨️ Print Shipping Label
      </button>
    </div>

    {/* Guidelines */}
    <div className="mt-10 space-y-6 text-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-teal-700">📦 Step 1: Pack Your Box</h2>
        <p className="text-lg text-gray-800 mt-3">
          Use a sturdy box and pack your items carefully. Fill any empty spaces with padding like bubble wrap or paper
          to protect your items during shipping. Seal the box tightly with packing tape.
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-green-600">📝 Step 2: Attach the Label</h2>
        <p className="text-lg text-gray-800 mt-3">
          Place the label on the top or largest side of the box. Make sure the barcode is clear and not bent. Secure it
          with clear tape without covering the barcode.
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-yellow-600">🚚 Step 3: Drop It Off</h2>
        <p className="text-lg text-gray-800 mt-3">
          Take your package to the nearest USPS location. You can find the closest drop-off point using the USPS website
          or app. Make sure to get a receipt for tracking your shipment!
        </p>
      </div>
    </div>
  </div>
);

const ComputerView: React.FC<{ labelUrl: string; handlePrintRedirect: () => void }> = ({ labelUrl, handlePrintRedirect }) => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-teal-100 to-blue-200 p-6">
    <h1 className="text-4xl font-bold text-center mb-6 text-purple-600 animate-bounce">
      📦 Computer View: Shipping Label
    </h1>
    <div
      className="bg-[#A0522D] p-10 border-4 border-[#5C4033] rounded shadow-lg cursor-pointer"
      onClick={handlePrintRedirect} // Trigger printing when the label container is clicked
    >
      <img
        src={labelUrl}
        alt="Shipping Label"
        className="max-w-[4in] max-h-[6in] w-full h-auto"
      />
    </div>
    <div className="mt-4">
      <button
        onClick={handlePrintRedirect}
        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
      >
        🖨️ Print Shipping Label
      </button>
    </div>

    {/* Guidelines */}
    <div className="mt-10 space-y-6 text-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-teal-700">📦 Step 1: Pack Your Box</h2>
        <p className="text-lg text-gray-800 mt-3">
          Use a sturdy box and pack your items carefully. Fill any empty spaces with padding like bubble wrap or paper
          to protect your items during shipping. Seal the box tightly with packing tape.
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-green-600">📝 Step 2: Attach the Label</h2>
        <p className="text-lg text-gray-800 mt-3">
          Place the label on the top or largest side of the box. Make sure the barcode is clear and not bent. Secure it
          with clear tape without covering the barcode.
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-yellow-600">🚚 Step 3: Drop It Off</h2>
        <p className="text-lg text-gray-800 mt-3">
          Take your package to the nearest USPS location. You can find the closest drop-off point using the USPS website
          or app. Make sure to get a receipt for tracking your shipment!
        </p>
      </div>
    </div>
  </div>
);

const ShippingLabelPrint: React.FC = () => {
  const location = useLocation();
  const { labelUrl } = location.state || {};

  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size
  useEffect(() => {
    const updateDeviceType = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    updateDeviceType();
    window.addEventListener('resize', updateDeviceType);

    return () => window.removeEventListener('resize', updateDeviceType);
  }, []);

  const handlePrintRedirect = () => {
    const state = { labelUrl };
    const url = `/print?${new URLSearchParams(state)}`;
    window.open(url, '_blank'); // Open the print page in a new tab
  };

  if (!labelUrl) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500 text-xl font-semibold">Error: Shipping label not found.</p>
      </div>
    );
  }

  return isMobile ? (
    <MobileView labelUrl={labelUrl} handlePrintRedirect={handlePrintRedirect} />
  ) : (
    <ComputerView labelUrl={labelUrl} handlePrintRedirect={handlePrintRedirect} />
  );
};

export default ShippingLabelPrint;
