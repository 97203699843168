import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import useDeviceToken from '../hooks/useDeviceToken';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
const image4 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image4.png';
import { fetchSignInMethodsForEmail } from 'firebase/auth'; // Import necessary Firebase auth methods
import { FaGoogle } from 'react-icons/fa';


const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Cart', href: '/get-offer' },
  { name: 'Profile', href: '/your-account' },
];

const AuthPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { signup, login, loginWithGoogle, resetPassword, currentUser,addToErrorQueue } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isProcessing, setIsProcessing] = useState(false);


  useDeviceToken();


  useEffect(() => {
    if (location.state && location.state.isLogin !== undefined) {
      setIsLogin(location.state.isLogin);
    }
  }, [location.state]);

  useEffect(() => {
    const checkAddress = async () => {
      if (currentUser) {
        const addressRef = doc(db, 'addresses', currentUser.uid);
        const addressDoc = await getDoc(addressRef);
        if (!addressDoc.exists()) {
          navigate('/your-account');
        }
      }
    };
    if (currentUser) {
      checkAddress();
    }
  }, [currentUser, navigate]);

  const validatePassword = (password: string) => {
    const minLength = /.{8,}/;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
  
    return {
      minLength: minLength.test(password),
      hasLowerCase: hasLowerCase.test(password),
      hasUpperCase: hasUpperCase.test(password),
      hasNumber: hasNumber.test(password),
    };
  };
  
  const PasswordCriteria: React.FC<{ criteria: ReturnType<typeof validatePassword> }> = ({ criteria }) => {
    return (
      <div className="mt-4 p-4 border rounded-lg bg-gray-50 shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">Password Requirements:</h3>
        <ul className="space-y-2">
          <li className={`flex items-center ${criteria.minLength ? 'text-green-600' : 'text-red-600'}`}>
            <div
              className={`w-4 h-4 mr-2 flex-shrink-0 rounded-full ${criteria.minLength ? 'bg-green-500' : 'bg-red-500'}`}
            ></div>
            At least 8 characters
          </li>
          <li className={`flex items-center ${criteria.hasLowerCase ? 'text-green-600' : 'text-red-600'}`}>
            <div
              className={`w-4 h-4 mr-2 flex-shrink-0 rounded-full ${criteria.hasLowerCase ? 'bg-green-500' : 'bg-red-500'}`}
            ></div>
            At least one lowercase letter
          </li>
          <li className={`flex items-center ${criteria.hasUpperCase ? 'text-green-600' : 'text-red-600'}`}>
            <div
              className={`w-4 h-4 mr-2 flex-shrink-0 rounded-full ${criteria.hasUpperCase ? 'bg-green-500' : 'bg-red-500'}`}
            ></div>
            At least one uppercase letter
          </li>
          <li className={`flex items-center ${criteria.hasNumber ? 'text-green-600' : 'text-red-600'}`}>
            <div
              className={`w-4 h-4 mr-2 flex-shrink-0 rounded-full ${criteria.hasNumber ? 'bg-green-500' : 'bg-red-500'}`}
            ></div>
            At least one number
          </li>
        </ul>
        <div className="mt-4">
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-green-500 h-2.5 rounded-full transition-all duration-500"
              style={{
                width: `${
                  [
                    criteria.minLength,
                    criteria.hasLowerCase,
                    criteria.hasUpperCase,
                    criteria.hasNumber,
                  ].filter(Boolean).length * 25
                }%`,
              }}
            ></div>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            {`Progress: ${
              [criteria.minLength, criteria.hasLowerCase, criteria.hasUpperCase, criteria.hasNumber].filter(Boolean)
                .length
            } / 4 met`}
          </p>
        </div>
      </div>
    );
  };
  
  
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
  });
  
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordCriteria(validatePassword(newPassword));
  };
  

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  
  const togglePasswordVisibility = () => setPasswordVisible((prev) => !prev);
  const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible((prev) => !prev);
  
  //C:\Users\omerb\OneDrive\Desktop\NewSeller\frontend\src\components\AuthPage.tsx
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // If the emails or passwords do not match, show an error notification
    if (!isLogin && (email !== confirmEmail || password !== confirmPassword)) {
      addToErrorQueue("Emails or passwords do not match.", 'error');
      return;
    }

    // If the password does not meet the criteria, show an error notification
    if (!isLogin && !validatePassword(password)) {
      addToErrorQueue("Password does not meet the required criteria.", 'error');
      return;
    }

    try {
      // Fetch sign-in methods for the provided email
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      // Check if the email is already registered with Google Sign-In
      if (signInMethods.includes('google.com')) {
        addToErrorQueue("This email is already registered with Google Sign-In. Please use Google Sign-In.", 'info');
        return;
      }

      // Check if the email is already registered with password
      if (signInMethods.includes('password')) {
        addToErrorQueue("This email is already registered. Please log in with your email and password.", 'info');
        return;
      }

      // Handle login case
      if (isLogin) {
        const result = await login(email, password, navigate); // Pass navigate to login function

        if (result && result.user) {
          if (!result.user.emailVerified) {
            await auth.signOut();
            addToErrorQueue('Please verify your email before logging in.', 'error');
            return;
          }
        } else {
          return;
        }
      } 
      // Handle sign-up case
      else {
        await signup(email, password);
        addToErrorQueue('A verification email has been sent to your inbox. Please verify your email before logging in.', 'success');

        // Set login view after closing notifications
        setIsLogin(true);
        navigate('/auth');
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Failed to authenticate:', error.message);
        addToErrorQueue('Failed to authenticate. Please try again.', 'error');
      }
    }
  };

  
  
  
  const handleGoogleSignIn = async () => {
    if (isProcessing) return;
  
    try {
      setIsProcessing(true);
  
      const result = await loginWithGoogle();
      const user = result.user;
  
      const signInMethods = await fetchSignInMethodsForEmail(auth, user.email!);
      if (signInMethods.includes('password')) {
        addToErrorQueue("This email is already registered with an email and password. Please use your email and password to log in.", 'info');
        await auth.signOut();
        setIsProcessing(false);
        return;
      }
  
      if (result && result.user) {
        if (!result.user.emailVerified) {
          await auth.signOut();
          addToErrorQueue('Your email is not verified. Please check your inbox and verify your email.', 'error');
          setIsProcessing(false);
          return;
        }
      } else {
        addToErrorQueue('Failed to sign in with Google. Please try again.', 'error');
      }
  
      navigate('/get-offer');
    } catch (error) {
      if (error instanceof Error) {
        console.error('Failed to sign in with Google:', error.message);
        addToErrorQueue(error.message, 'error');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await resetPassword(email);
      addToErrorQueue('Password reset email sent. Please check your inbox.', 'success');
    } catch (error) {
      if (error instanceof Error) {
        console.error('Failed to send password reset email:', error.message);
        addToErrorQueue(error.message, 'error');
      }
    }
  };
  

return (
<div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-400 via-purple-500 to-indigo-900 relative overflow-hidden">
  {/* Animated Gradient Background */}
  <div className="absolute inset-0 bg-gradient-to-br from-pink-300 via-purple-300 to-indigo-500 opacity-40 animate-[gradientShift_10s_infinite] z-0"></div>

  {/* Pattern Overlay for Texture */}
  <div className="absolute inset-0 bg-[url('https://www.transparenttextures.com/patterns/cubes.png')] opacity-20 z-0"></div>

  {/* Glowing Effects for Depth */}
  <div className="absolute top-1/3 left-0 h-96 w-96 bg-blue-400 opacity-40 rounded-full filter blur-3xl animate-pulse z-0"></div>
  <div className="absolute bottom-1/4 right-0 h-96 w-96 bg-purple-400 opacity-40 rounded-full filter blur-3xl animate-pulse z-0"></div>

  {/* Header */}
  <header className="w-full bg-[#b3dff5] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
    <div className="flex items-center justify-center flex-1">
      <Link to="/" className="text-3xl font-bold text-gray-900 flex items-center space-x-3 hover:text-blue-700 transition duration-300" aria-label="BooksAreReborn Home">
        <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto animate-bounce" />
        <span className="tracking-wider">BooksAreReborn</span>
      </Link>
      <nav className="hidden lg:flex space-x-6 mx-auto">
        {navigation.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className="text-lg font-medium text-gray-700 hover:text-blue-700 px-3 py-2 rounded-lg hover:bg-gray-100 transition duration-300"
          >
            {item.name}
          </Link>
        ))}
      </nav>
    </div>
    <div className="flex items-center space-x-4">
      <button
        type="button"
        onClick={() => setMobileMenuOpen(true)}
        className="lg:hidden p-2 rounded-full bg-gray-900 text-white hover:bg-gray-800 transition transform hover:scale-105"
        aria-label="Open mobile menu"
      >
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      </button>
    </div>
  </header>

  {/* Mobile Menu */}
  <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
    <div className="fixed inset-0 z-50 bg-black bg-opacity-40" />
    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#57b0dc] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 shadow-xl rounded-l-2xl">
    <div className="flex items-center justify-between">
        <Link to="/" className="flex items-center space-x-2" aria-label="BooksAreReborn Home">
          <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto" />
        </Link>
        <button
          type="button"
          onClick={() => setMobileMenuOpen(false)}
          className="p-2 text-gray-700 hover:text-gray-900 transition"
          aria-label="Close mobile menu"
        >
          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
        </button>
      </div>
      <div className="mt-6">
        <div className="py-6 space-y-4">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="block rounded-lg px-3 py-2 text-lg font-medium text-gray-900 hover:bg-gray-50 transition duration-300"
              aria-label={`Navigate to ${item.name}`}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </DialogPanel>
  </Dialog>

{/* Main Content */}
<main className="flex-grow pt-24 flex flex-col items-center justify-center px-4 bg-gradient-to-br from-blue-50 via-indigo-50 to-gray-300 min-h-screen">
  <div className="w-full max-w-xl bg-white p-10 rounded-3xl shadow-2xl transform hover:scale-105 transition-all duration-500 ease-in-out hover:shadow-3xl">
    <h1 className="text-5xl font-extrabold text-gray-800 mb-6 text-center tracking-wide leading-tight">
      {isLogin ? 'Welcome Back!' : 'Join BooksAreReborn Today!'}
    </h1>
    <p className="text-gray-600 text-lg mb-8 text-center leading-relaxed">
      {isLogin ? 'Log in to access your account.' : 'Create a new account and start selling your books effortlessly.'}
    </p>
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Email field */}
      <input
        type="email"
        name="email" // Add name attribute for browser autofill
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete={isLogin ? "username" : "email"} // Use "username" for login autofill
        className="w-full p-4 border border-gray-300 rounded-xl shadow-inner focus:outline-none focus:ring-4 focus:ring-blue-500 transition-all"
        required
      />
      {/* Confirm Email field */}
      {!isLogin && (
        <input
          type="email"
          name="confirm-email" // Different name for confirmation
          placeholder="Confirm Email"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
          autoComplete="email"
          className="w-full p-4 border border-gray-300 rounded-xl shadow-inner focus:outline-none focus:ring-4 focus:ring-blue-500 transition-all"
          required
        />
      )}
    {/* Password field */}
    <div className="relative">
      <input
        type={passwordVisible ? "text" : "password"}
        name="password" // Add name attribute for browser autofill
        placeholder="Password"
        value={password}
        onChange={handlePasswordChange}
        autoComplete={isLogin ? "current-password" : "new-password"} // Use "current-password" for login autofill
        className="w-full p-4 border border-gray-300 rounded-xl shadow-inner focus:outline-none focus:ring-4 focus:ring-blue-500 transition-all"
        required
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute inset-y-0 right-4 flex items-center text-gray-600 hover:text-gray-800"
        aria-label="Toggle password visibility"
      >
        {passwordVisible ? "Hide" : "Show"}
      </button>
    </div>

    {/* Show criteria only for sign-up */}
    {!isLogin && <PasswordCriteria criteria={passwordCriteria} />}


      {/* Confirm Password field */}
      {!isLogin && (
        <div className="relative">
          <input
            type={confirmPasswordVisible ? "text" : "password"}
            name="confirm-password" // Different name for confirmation
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            autoComplete="new-password"
            className="w-full p-4 border border-gray-300 rounded-xl shadow-inner focus:outline-none focus:ring-4 focus:ring-blue-500 transition-all"
            required
          />
          <button
            type="button"
            onClick={toggleConfirmPasswordVisibility}
            className="absolute inset-y-0 right-4 flex items-center text-gray-600 hover:text-gray-800"
            aria-label="Toggle confirm password visibility"
          >
            {confirmPasswordVisible ? "Hide" : "Show"}
          </button>
        </div>
      )}

      {/* Submit button */}
      <button
        type="submit"
        className="w-full py-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-semibold rounded-xl shadow-lg hover:bg-blue-700 transition-transform duration-300 transform hover:scale-105"
      >
        {isLogin ? 'Log In' : 'Sign Up'}
      </button>
      {/* Toggle Login/Sign-Up */}
      <button
        type="button"
        onClick={() => setIsLogin(!isLogin)}
        className="w-full mt-4 py-3 text-blue-600 hover:text-blue-700 font-medium transition-all transform hover:scale-105"
      >
        {isLogin ? 'Need an account? Sign Up' : 'Have an account? Log In'}
      </button>
      {/* Password Reset */}
      {isLogin && (
        <button
          type="button"
          onClick={handlePasswordReset}
          className="w-full mt-4 py-3 text-red-500 hover:text-red-600 font-medium transition-all transform hover:scale-105"
        >
          Forgot Password?
        </button>
      )}
    </form>
    {/* Google Sign-In Button */}
    <div className="mt-6">
      <button
        type="button"
        onClick={handleGoogleSignIn}
        className="w-full py-3 bg-red-500 text-white font-semibold rounded-xl shadow-lg hover:bg-red-600 transition-transform duration-300 transform hover:scale-105 flex items-center justify-center space-x-2"
      >
        <FaGoogle className="h-5 w-5" />
        <span>Sign in with Google</span>
      </button>
    </div>
  </div>
</main>


    {/* Footer */}
    <footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
        <a
          href="https://www.affiliatly.com/af-1070204/affiliate.panel?mode=register"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Affiliate Program
        </a>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>

  </div>
);
};

export default AuthPage;