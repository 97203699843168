import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
const image4 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image4.png';

const TermsOfUse: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#c3e7f5] via-[#0e4668] to-[#121212]">
    <header className="w-full bg-[#b3dff5] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
    <div className="flex items-center justify-center flex-1">
      <Link to="/" className="text-3xl font-bold text-gray-900 flex items-center space-x-3 hover:text-blue-700 transition duration-300" aria-label="BooksAreReborn Home">
        <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto animate-bounce" />
        <span className="tracking-wider">BooksAreReborn</span>
      </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Your Account
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Cart
            </Link>

          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
            >
              Log out
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate('/auth')}
                className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign In
              </button>
              <button
                onClick={handleSignUpClick}
                className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign Up
              </button>
            </>
          )}
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#57b0dc] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 shadow-xl rounded-l-2xl">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link to="/" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Home
                </Link>
                <Link to="/your-account" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Your Account
                </Link>
                <Link to="/get-offer" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Cart
                </Link>

              </div>
              <div className="py-6">
                {currentUser ? (
                  <button
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => navigate('/auth')}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      <main className="flex-grow pt-24 flex justify-center items-center">
  <section className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg text-center">
    <h1 className="text-4xl font-bold text-gray-900 mb-4">Terms and Conditions</h1>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Introduction</h2>
    <p className="text-gray-700 mb-4">
      These Terms and Conditions ("Terms") govern your use of the BooksAreReborn website and related services ("Site"). By accessing or using the Site, you agree to comply with and be bound by these Terms.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Transaction Terms</h2>
    <p className="text-gray-700 mb-4">
      BooksAreReborn provides offers based on the information you submit about your books. Final offers are subject to our review and inspection upon receiving the books. If the condition of the books does not match the submitted details, the offer may be adjusted, or the books may be returned at your expense. Ownership of the books transfers to BooksAreReborn once payment is issued.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Shipping Responsibilities</h2>
    <p className="text-gray-700 mb-4">
      BooksAreReborn provides prepaid shipping labels for sending books. You are responsible for packaging your books securely to avoid damage during transit. If books are rejected after inspection, return shipping costs may be deducted from your payment.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Payments</h2>
    <p className="text-gray-700 mb-4">
      Payments are issued via PayPal or check after the inspection process is complete. It is your responsibility to ensure that the PayPal email address or mailing address provided is accurate. Processing times for payments may vary.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Prohibited Items</h2>
    <p className="text-gray-700 mb-4">
      The following items are prohibited from being sold to BooksAreReborn:
    </p>
    <ul className="list-disc list-inside text-gray-700 mb-4">
      <li>Stolen or counterfeit books.</li>
      <li>Books with missing pages or extensive damage.</li>
      <li>Books that violate copyright or intellectual property laws.</li>
    </ul>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Fraud and Misrepresentation</h2>
    <p className="text-gray-700 mb-4">
      BooksAreReborn reserves the right to cancel transactions, withhold payments, or ban accounts if fraud, false information, or misuse is detected. We also reserve the right to take legal action if necessary.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Website Availability</h2>
    <p className="text-gray-700 mb-4">
      We strive to ensure that the Site is available at all times, but we cannot guarantee uninterrupted access. We may modify, suspend, or discontinue the Site or certain services at any time, without notice.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Your Use of the Site</h2>
    <p className="text-gray-700 mb-4">
      You agree to use the Site only for lawful purposes and in a way that does not infringe the rights of others or restrict their use and enjoyment of the Site. You must not misuse the Site by knowingly introducing viruses, harmful material, or attempting to exploit the Site's functionalities in any unauthorized manner.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Intellectual Property Rights</h2>
    <p className="text-gray-700 mb-4">
      All content on the Site, including text, graphics, logos, and images, is the property of BooksAreReborn or its licensors. You may not use, reproduce, or distribute any content without our express written consent.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Disclaimer of Warranties</h2>
    <p className="text-gray-700 mb-4">
      The Site is provided "as is" and without warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to implied warranties of merchantability and fitness for a particular purpose.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Limitation of Liability</h2>
    <p className="text-gray-700 mb-4">
      To the fullest extent permitted by law, BooksAreReborn is not liable for any damages arising from your use of the Site or its content, including but not limited to direct, indirect, incidental, punitive, and consequential damages. This includes damages related to delays in payment or lost shipments unless caused by our negligence.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Modifications to the Terms</h2>
    <p className="text-gray-700 mb-4">
      We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on the Site. Your continued use of the Site following the posting of changes constitutes your acceptance of those changes.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Governing Law and Dispute Resolution</h2>
    <p className="text-gray-700 mb-4">
      These Terms are governed by and construed in accordance with the laws of the United States and the state of Texas. Any disputes arising under these Terms will first be attempted to resolve informally. If unresolved, disputes will be subject to arbitration in accordance with applicable laws.
    </p>

    <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
    <p className="text-gray-700">
      If you have any questions about these Terms, please contact us via our <Link to="/contact-us" className="text-blue-500 hover:underline">Contact Us</Link> page.
    </p>
  </section>
</main>

      <br />
      <footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>
    </div>
  );
};

export default TermsOfUse;
