import React, { useState } from 'react';
import axios from 'axios';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ClipLoader } from 'react-spinners';
import ga from '../ga-init';  // Import your Google Analytics helper



// Importing images
const image1 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image1.webp';
const image2 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image2.webp';
const image3 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image3.webp';
const image4 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image4.png';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Cart', href: '/get-offer' },
  { name: 'Account', href: '/your-account' },
];

export default function Home() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isbn, setIsbn] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for API call
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const trackButtonClick = ga.trackEventBuilder('Button');

  // Check for cookie consent
  const isAnalyticsEnabled = () => {
    return localStorage.getItem('cookieConsent') === 'accepted';
  };


  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    if (isAnalyticsEnabled()) {
      trackButtonClick({ action: 'logout_click', label: 'Logout' });  // Track logout click event
    }
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleIsbnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsbn(e.target.value);
  };


// Function to convert ISBN-10 to ISBN-13
const convertIsbn10ToIsbn13 = (isbn10: string): string => {
  const isbnWithoutCheckDigit = '978' + isbn10.substring(0, 9);
  const digits = isbnWithoutCheckDigit.split('').map(Number);
  const checkDigit = digits.reduce((sum, digit, index) => sum + digit * (index % 2 === 0 ? 1 : 3), 0);
  const remainder = checkDigit % 10;
  const isbn13CheckDigit = remainder === 0 ? 0 : 10 - remainder;
  return isbnWithoutCheckDigit + isbn13CheckDigit;
};

// Function to format ISBN and convert ISBN-10 to ISBN-13 if necessary
const formatIsbn = (isbn: string): string => {
  const formattedIsbn = isbn.replace(/-/g, '').trim();

  // Convert ISBN-10 to ISBN-13 if necessary
  if (formattedIsbn.length === 10) {
    return convertIsbn10ToIsbn13(formattedIsbn);
  }

  return formattedIsbn; // Return ISBN-13 as-is
};

axios.defaults.withCredentials = true;

const handleGetOffer = async () => {
  try {
    setLoading(true); // Set loading state when API call starts
    const formattedIsbn = formatIsbn(isbn);
    console.log('Formatted ISBN:', formattedIsbn);
    if (isAnalyticsEnabled()) {
      trackButtonClick({ action: 'logout_click', label: 'Logout' });  // Track logout click event
    }

    let existingCart = [];

    if (currentUser) {
      const cartRef = doc(db, 'carts', currentUser.uid);
      const cartDoc = await getDoc(cartRef);

      if (cartDoc.exists()) {
        existingCart = cartDoc.data().shopping_cart || [];
        console.log('Existing Cart from Firestore:', existingCart);
      }
    } else {
      existingCart = JSON.parse(localStorage.getItem('shoppingCart') || '[]');
      console.log('Existing Cart from localStorage :', existingCart);
    }

    const isAlreadyInCart = existingCart.some((item: any) => formatIsbn(item.isbn) === formattedIsbn);
    console.log('Is already in cart:', isAlreadyInCart);

    if (isAlreadyInCart) {
      navigate('/get-offer');
      setLoading(false); // Turn off loading if redirecting early
      return;
    }

    // Validate the formatted ISBN (check if it's either 10 or 13 digits)
    if (formattedIsbn.length !== 13) {
      // Redirect to the Getoffer page, passing the error message
      navigate('/get-offer', { state: { errorMessage: 'Please enter a valid 10 or 13-digit ISBN.' } });
      setLoading(false); // Turn off loading if ISBN is invalid
      return;
    }

    // Proceed with the API call and cart update logic as before...
    const response = await axios.post('https://resellerapp-backend.azurewebsites.net/add_to_cart', { isbn: formattedIsbn, quantity: 1 });
    const { shopping_cart: newCartItems } = response.data;

    if (!newCartItems || newCartItems.length === 0) {
      console.error('Received empty shopping cart from backend');
      setLoading(false); // Turn off loading if no items were found
      return;
    }

    existingCart = existingCart.concat(newCartItems);


    if (currentUser) {
      const cartRef = doc(db, 'carts', currentUser.uid);
      await setDoc(cartRef, { shopping_cart: existingCart }, { merge: true });
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(existingCart));
    }

    console.log('Existing Cart after adding:', existingCart);
    navigate('/get-offer');
  } catch (error) {
    console.error('Error adding to cart:', error);
  } finally {
    setLoading(false); // Ensure loading is turned off after API call
  }
};


  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
    if (isAnalyticsEnabled()) {
      trackButtonClick({ action: 'logout_click', label: 'Logout' });  // Track logout click event
    }
  };




  
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-green-50 to-green-100 text-gray-800">
  <header className="w-full bg-[#b3dff5] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
    <div className="flex items-center justify-center flex-1">
      <Link to="/" className="text-3xl font-bold text-gray-900 flex items-center space-x-3 hover:text-blue-700 transition duration-300" aria-label="BooksAreReborn Home">
        <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto animate-bounce" />
        <span className="tracking-wider">BooksAreReborn</span>
      </Link>
      <nav className="hidden lg:flex space-x-6 mx-auto">
        {navigation.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className="text-lg font-medium text-gray-700 hover:text-blue-700 px-3 py-2 rounded-lg hover:bg-gray-100 transition duration-300"
          >
            {item.name}
          </Link>
        ))}
      </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
            >
              Log out
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate('/auth')}
                className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign In
              </button>
              <button
                onClick={handleSignUpClick}
                className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign Up
              </button>
            </>
          )}
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-lg font-semibold mb-4">Your offer is being calculated</h2>
            <p className="text-gray-600 mb-4">Please wait, you will be redirected shortly...</p>
            <ClipLoader color="#3498db" size={40} /> {/* Spinner */}
          </div>
        </div>
      )}

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#57b0dc] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 shadow-xl rounded-l-2xl">
        <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto" // Same height adjustment for consistency
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                {currentUser ? (
                  <button
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => navigate('/auth')}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      <main className="flex-grow pt-32 bg-gradient-to-b from-blue-950 via-indigo-900 to-gray-900">
  <div className="container mx-auto max-w-7xl px-8 py-20 text-center animate-fade-in">
    {/* Header Section */}
    <h1 
  className="text-6xl sm:text-8xl font-extrabold mb-10 tracking-tight leading-tight"
  style={{ color: '#FFD700', textShadow: '0 0 15px rgba(255, 215, 0, 1)' }}
>
  Sell Your Books, <> <br /></>Earn Instant Cash!
</h1>

    <p className="text-xl sm:text-2xl text-gray-300 mb-14 leading-relaxed max-w-2xl mx-auto drop-shadow-md animate-fade-in-slower">
    Your books could be worth more than you think. Enter the ISBN below for a quick, competitive offer and cash in today. Fast, simple, and rewarding.    </p>

    <div className="flex flex-col items-center space-y-6 animate-fade-in-fast">
      <input
        type="text"
        placeholder="Enter ISBN"
        value={isbn}
        onChange={handleIsbnChange}
        className="w-full max-w-xl rounded-full border-2 border-indigo-500 p-4 text-lg text-center mb-4 text-indigo-900 bg-white shadow-xl focus:outline-none focus:ring-4 focus:ring-indigo-400 hover:shadow-indigo-400 transition-all duration-300 ease-in-out animate-pulse"
      />
      <button
        onClick={handleGetOffer}
        className="bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:from-yellow-500 hover:to-yellow-700 text-indigo-900 font-bold py-3 px-10 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 focus:ring-4 focus:ring-yellow-500 animate-bounce-slight"
      >
        Get Offer
      </button>

      <Link
        to="/buyback-guide"
        className="mt-4 text-lg font-semibold text-white hover:text-yellow-300 transition-colors duration-300 animate-fade-in"
      >
        Learn more <span aria-hidden="true" className="inline-block transition-transform duration-200 transform group-hover:translate-x-1">→</span>
      </Link>

      <div className="mt-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 animate-fade-in-fast">
        <div className="flex flex-col items-center transform hover:scale-105 transition-transform duration-500">
          <img
            src={image1}
            alt="Guide 1"
            className="w-full h-auto rounded-xl shadow-2xl mb-6 transition-transform duration-500 transform hover:scale-110"
          />
          <div className="bg-gradient-to-b from-white to-gray-100 p-8 rounded-xl shadow-2xl transition-shadow duration-300 hover:shadow-yellow-400">
            <h2 className="text-2xl font-bold text-indigo-900 mb-3">
              Guide 1: Get an Offer & Create Shipment
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Locate the ISBN on the back cover of your book, typically found above the barcode. Enter the ISBN in the field above to receive an instant offer. Once you've added all your items, proceed to create your shipment and get your prepaid shipping label!
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center transform hover:scale-105 transition-transform duration-500">
          <img
            src={image2}
            alt="Guide 2"
            className="w-full h-auto rounded-xl shadow-2xl mb-6 transition-transform duration-500 transform hover:scale-110"
          />
          <div className="bg-gradient-to-b from-white to-gray-100 p-8 rounded-xl shadow-2xl transition-shadow duration-300 hover:shadow-yellow-400">
            <h2 className="text-2xl font-bold text-indigo-900 mb-3">
              Guide 2: Print Label & Pack Your Items
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Print your shipping label and securely pack your items in a box. Attach the label and drop off the package at the specified shipping carrier.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center transform hover:scale-105 transition-transform duration-500">
          <img
            src={image3}
            alt="Guide 3"
            className="w-full h-auto rounded-xl shadow-2xl mb-6 transition-transform duration-500 transform hover:scale-110"
          />
          <div className="bg-gradient-to-b from-white to-gray-100 p-8 rounded-xl shadow-2xl transition-shadow duration-300 hover:shadow-yellow-400">
            <h2 className="text-2xl font-bold text-indigo-900 mb-3">
              Guide 3: Get Paid Quickly!
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Once your shipment is processed, you’ll receive payment within 3-5 business days via PayPal or check.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
        <a
          href="https://www.affiliatly.com/af-1070204/affiliate.panel?mode=register"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Affiliate Program
        </a>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>

      </div>
    );
  };
